import React, { useEffect, useState } from "react";
import AirtableDialogBox from "./AirtableDialogBox.js";
import Config from "../utils/Config.js";
import utils from "../utils/helper.js";
import ApiClient from "../api-client/apiClient.js";
import CircularProgress from "@material-ui/core/CircularProgress";

const AirtableImport = () => {
  const [loading, setLoading] = useState(false);

  // Airtable Auth
  const redirectToAirtableAuth = async () => {
    const serviceUserUrl = `${Config.baseUrl}/${Config.getUserAuthUrl}?redirectUri=${encodeURIComponent(Config.baseUrl)}`;
    window.location = serviceUserUrl;
  };

  // call for refreshing token
  const refreshToken = async () => {
    setLoading(true);
    try {
      await ApiClient.refreshToken();
    } catch (err) {
      redirectToAirtableAuth();
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // On Click of Sync Button, handle the Airtable Auth
  const handleNavigate = async () => {
    if (window?.location?.search) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const access_token = urlSearchParams.get("access_token");
      const expires_in = urlSearchParams.get("expires_in");
      const refresh_token = urlSearchParams.get("refresh_token");
      const refresh_expires_in = urlSearchParams.get("refresh_expires_in");
      utils.setAuthToken(access_token, expires_in, refresh_token, refresh_expires_in);

      window.location = Config.baseUrl;
    } else {
      const accessToken = localStorage.getItem("airtableAT");
      const accessTokenExpiry = localStorage.getItem("airtableATExp");

      const isTokenValid = utils.verifyTokenValidity(accessToken, accessTokenExpiry);
      if (!isTokenValid) await refreshToken();
    }
  };

  // handle auth check
  useEffect(() => {
    handleNavigate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignOut = () => {
    utils.clearTokens();
    redirectToAirtableAuth();
  }

  return (
    <div>
      {
        (!loading && localStorage.getItem("airtableAT")) ?
          <AirtableDialogBox
            redirectToAirtableAuth={redirectToAirtableAuth}
            handleSignOut={handleSignOut}
          />
          :
          <div style={{ widht: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </div>
      }
    </div>
  );
};

export default AirtableImport;
