var utils = {
    setAuthToken: function (accessToken, accessTokenExpiry, refreshToken, refreshTokenExpiry) {
        localStorage.setItem("airtableAT", accessToken);
        localStorage.setItem("airtableATExp", Math.floor(Date.now() / 1000) + parseInt(accessTokenExpiry));
        localStorage.setItem("airtableRT", refreshToken);
        localStorage.setItem("airtableRTExp", Math.floor(Date.now() / 1000) + parseInt(refreshTokenExpiry));
    },
    verifyTokenValidity: function (token, tokenExpiry) {
        if(!token || !tokenExpiry) return false;
        if (Math.floor(Date.now() / 1000) <= tokenExpiry) return true;
        return false;
    },
    clearTokens: function () {
        localStorage.clear();
    }
}

export default utils;