import React, { useEffect, useState } from "react";
import DialogTitle from "@material-ui/core//DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Config from "../utils/Config";
import ApiClient from "../api-client/apiClient";
import "../index.css";

const headerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: "0.5rem"
};


//Override the CSS for some Material UI Components
const GlobalCss = withStyles({
    "@global": {
        ".MuiPaper-root": {
            overflowX: "hidden",
        },
        ".MuiButton-containedPrimary": {
            backgroundColor: "var(--primary-color, #2E7AE7)"
        },
        ".MuiButton-containedPrimary:hover": {
            backgroundColor: "var(--primary-color, #2E7AE7)",
            boxShadow: "0 1px 2px 1px var(--primary-color, #2E7AE7)"
        },
        ".MuiButton-containedSecondary": {
            backgroundColor: "var(--secondary-color, #515151)"
        },
        ".MuiButton-containedSecondary:hover": {
            backgroundColor: "var(--secondary-color, #515151)",
            boxShadow: "0 1px 2px 1px var(--secondary-color, #515151)"
        }
    },
})(() => null);

const useStyles = makeStyles({
    MuiDialog: {
        height: "auto",
        width: "auto",
    },
    MuiDialogTitle: {
        padding: "1rem 0 1.5rem 0",
        textAlign: "center",
        color: "var(--secondary-color, #515151)"
    },
    MuiFormControl: {
        width: "100%",
    },
    MuiSelect: {
        margin: "2rem 0.4rem",
        padding: "0.5rem 0rem",
        height: "auto",
    },
    MuiInputLabel: {
        margin: "0px 0.4rem 0px 0.4rem"
    },
});

const AirtableDialogBox = ({ redirectToAirtableAuth, handleSignOut }) => {
    const [base, setBase] = useState("");
    const [table, setTable] = useState("");
    const [bases, setBases] = useState([]);
    const [tables, setTables] = useState([]);
    const [toggleBaseFields, setToggleBaseFields] = useState(false);
    const [toggleTableFields, setToggleTableFields] = useState(false);
    const [onError, setOnError] = useState(null);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    //on access token change, get bases module
    const getBases = async () => {
        const serviceURL = `${Config.airtableProductUrl}/v0/meta/bases`;
        setLoading(true);
        try {
            const response = await ApiClient.fetchGetCall(serviceURL);
            setBases(response?.data["bases"]);
            setToggleBaseFields(true);
        } catch (error) {
            console.log("Error in fetching base: ", error);
            if ([401, 403].includes(error?.status || error?.response?.status || error)) redirectToAirtableAuth();
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBases();

        // Getting code from URL
        const url = window.location.href;
        let code = "";
        if (url.search("code=") !== -1) code = url.split("code=")[1].split("&")[0];
        if (code) window.history.pushState({}, document.title, "/");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // on base change, get tables module
    const handleChangeBase = async (baseId) => {
        setToggleTableFields(false);
        setBase(baseId);
        const serviceURL = `${Config.airtableProductUrl}/v0/meta/bases/${baseId}/tables`;
        try {
            const response = await ApiClient.fetchGetCall(serviceURL);
            const data = response?.data;

            setTables(data["tables"]);
        } catch (error) {
            console.log("Error in changing base: ", error);
            if ([401, 403].includes(error?.status || error?.response?.status || error)) redirectToAirtableAuth();
            return;
        } finally {
            setToggleTableFields(true);
        }
    };

    // Handle on Base and Table Data Submit
    const handleUpdate = async () => {
        if (!table) setOnError({ message: "Please Select a Table" });
        const airTableData = [];
        const serviceURL = `${Config.airtableProductUrl}/v0/${base}/${table}`;

        try {
            const response = await ApiClient.fetchGetCall(serviceURL);
            if (response?.data?.records)
                response.data.records.forEach((record) => {
                    airTableData.push(record.fields);
                });
        } catch (error) {
            console.log("Error in fetching tables: ", error);
            if ([401, 403].includes(error?.status || error?.response?.status || error)) redirectToAirtableAuth();
            return;
        }
        let headersSet = new Set();
        airTableData.map((airData) => (headersSet = new Set([...headersSet, ...Object.keys(airData)])));

        window.opener.postMessage({
            data: JSON.stringify({ data: airTableData, headers: [...headersSet], sheetName: table })
        }, '*');

    };

    return (
        <div>
            <GlobalCss />
            {onError && <p>Signout</p>}
            <header style={headerStyle}>
                <Button variant="contained" color="secondary" size="small" style={{ textTransform: "none" }} onClick={handleSignOut}>
                    Sign Out
                </Button>
            </header>
            <div className="airtable-container">
                {loading ? (
                    <CircularProgress style={{ margin: "0 auto", display: "flex" }} />
                ) : (
                    <>
                        <DialogTitle className={classes.MuiDialogTitle}>Select a table</DialogTitle>
                        <FormControl className={classes.MuiFormControl}>
                            <InputLabel id="demo-simple-select-label" className={classes.MuiInputLabel}>
                                Base
                            </InputLabel>
                            <Select
                                className={classes.MuiSelect}
                                labelId="base-select"
                                disabled={!toggleBaseFields}
                                id="base-select"
                                value={base}
                                label="Base"
                                onChange={(e) => {
                                    handleChangeBase(e.target.value);
                                }}
                            >
                                {bases &&
                                    bases.map((base, index) => (
                                        <MenuItem key={index} value={base.id}>
                                            {base.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.MuiFormControl}>
                            <InputLabel id="demo-simple-select-label" className={classes.MuiInputLabel}>
                                Table
                            </InputLabel>
                            <Select
                                labelId="table-select"
                                className={classes.MuiSelect}
                                disabled={!toggleTableFields}
                                id="table-select"
                                value={table}
                                label="Tables"
                                onChange={(e) => {
                                    setTable(e.target.value);
                                }}
                            >
                                {tables &&
                                    tables?.map((table, index) => (
                                        <MenuItem key={index} value={table.name}>
                                            {table.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Button
                            className={[classes.MuiFormControl]}
                            onClick={handleUpdate}
                            variant="contained"
                            color="primary"
                        >
                            Import
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default AirtableDialogBox;