import Config from "../utils/Config";
import axios from "axios";
import utils from "../utils/helper";

export class ApiClient {
    static async fetchPostCall(serviceUrl, postData, additionalHeaders = {}) {
        await this.checkTokenValidity();

        let headers = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", ...(additionalHeaders ? additionalHeaders : {}) };
        const accessToken = localStorage.getItem("airtableAT");
        headers["Authorization"] = `Bearer ${accessToken}`;

        const promise = fetch(serviceUrl, {
            method: "POST",
            body: JSON.stringify(postData),
            headers: headers,
            credentials: "include",
        });

        return promise;
    }

    static async fetchGetCall(serviceUrl, additionalHeaders = {}) {
        await this.checkTokenValidity();

        let headers = { ...(additionalHeaders ? additionalHeaders : {}) };
        const accessToken = localStorage.getItem("airtableAT");
        headers["Authorization"] = `Bearer ${accessToken}`;

        const promise = await axios({
            method: "GET",
            url: serviceUrl,
            headers
        });

        return promise;
    }

    static async fetchPostCallUnAuth(serviceUrl, postData, additionalHeaders) {
        let headers = { ...(additionalHeaders ? additionalHeaders : {}) };

        const promise = await fetch(serviceUrl, {
            method: "POST",
            body: JSON.stringify(postData),
            headers: headers,
            credentials: "include",
        });

        return promise;
    }

    static async fetchGetCallUnAuth(serviceUrl, additionalHeaders) {
        let headers = { ...(additionalHeaders ? additionalHeaders : {}) };

        const promise = await fetch(serviceUrl, {
            method: "GET",
            headers: headers,
            credentials: "include",
        });

        return promise;
    }

    static async checkTokenValidity() {
        try {
            let refreshTokenLock = sessionStorage.getItem("airtableRTLock");
            let count = 0;

            while (refreshTokenLock && count < 15) {
                await this.wait(count > 0 ? 200 * count : undefined); //delays the next check of refreshTokenLock
                refreshTokenLock = sessionStorage.getItem("airtableRTLock");
                count += 1;
            }

            const token = localStorage.getItem("airtableAT");
            const accessTokenExpiry = localStorage.getItem("airtableATExp");
            if (!utils.verifyTokenValidity(token, accessTokenExpiry)) await this.refreshToken();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    static setRefreshTokenLock(value) {
        if (!value) sessionStorage.removeItem("airtableRTLock");
        else sessionStorage.setItem("airtableRTLock", value);
    }

    static async wait(time = 1200) {
        return new Promise((resolve) => {
            setTimeout(function () {
                resolve();
            }, time);
        });
    }

    static async refreshToken() {
        const refreshToken = localStorage.getItem("airtableRT");
        const refreshTokenExpiry = localStorage.getItem("airtableRTExp");
        this.setRefreshTokenLock(true);
        try {
            if (!refreshToken || !refreshTokenExpiry ) throw new Error(401);
            const reqBody = { refresh_token: refreshToken };
            
            await fetch(`${Config.baseUrl}/refresh`, {
                method: "POST",
                body: JSON.stringify(reqBody),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "*/*",
                },
                credentials: "include",
            }).then(async (response) => {
                if (!response.ok) throw response.status;
                let data = await response.json();
                const { access_token, expires_in, refresh_token, refresh_expires_in } = data;
                utils.setAuthToken(access_token, expires_in, refresh_token, refresh_expires_in);
            });
        } catch (err) {
            console.log(err);
            throw err;
        } finally {
            this.setRefreshTokenLock(false);
        }
    }
}

export default ApiClient;